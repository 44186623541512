
import KycRejectCommentModal from "@/components/KycRejectCommentModal.vue";
import CommentModal from "@/components/CommentModal.vue";
import { KycRequest } from "@/types/KycRequest";

export default {
  name: "KycRequests",
  components: {CommentModal, KycRejectCommentModal},
  methods: {
    approve(kycRequest) {
      this.axios.patch(`${process.env.VUE_APP_BACKEND_ACCOUNT_URL}/accounts/${kycRequest.userUid}/kyc`, {status:"PASSED"}).finally((resp) => {
          this.snackbarText = `Запрос KYC от пользователя ${kycRequest.userUid} принят и обработан`;
          this.snackbar = true;
          this.load({page: this.currentPage});
      })
    },
    declineClosed() {
      this.showModal = false;
      this.selectedComment = undefined;
    },
    declinedSuccessfully() {
      this.snackbarText = `Запрос KYC от пользователя ${this.selectedRequest.userUid} отклонен`;
      this.snackbar = true;
      this.load({page: this.currentPage})
    },
    decline(kycRequest) {
      this.showModal = true;
      this.selectedRequest = kycRequest;
      this.selectedComment = kycRequest.comment;
    },
    downloadPassport(kycRequest) {
      this.axios.get(`${process.env.VUE_APP_BACKEND_ACCOUNT_URL}/accounts/${kycRequest.userUid}/kyc/passportPages`)
        .then((response) => {
          response.data.pages.forEach(element => {
            const a = document.createElement("a");
            a.href += "data:" + response.headers["content-type"] + ";base64," + element.passportPhoto;
            a.download = element.photoName;
            a.click()
          })
        });
    },
    changeStatus() {
      this.currentPage = 0
      this.load( {page : this.currentPage})
    },
    load({page, sortBy}) {
      let currentStatus = this.status;
      if (currentStatus == "NEED_EXAMINATION") currentStatus = null;
      this.currentPage = Math.max(0, page - 1);
      this.axios.get( `${process.env.VUE_APP_BACKEND_ACCOUNT_URL}/accounts/kyc`, {
        params: {
          status: currentStatus,
          page: this.currentPage,
          size: this.itemsPerPage,
          sort: this.sortBy,
          search: this.search,
        },
      })
      .then(res => {
        this.kycRequests = res.data.content.map(request => ({
          userId : request.userId,
          lastModifiedAt : request.lastModifiedAt,
          userUid : request.userUid,
          passportFirstName : request.passport.passportFirstName,
          passportLastName : request.passport.passportLastName,
          sNoMiddleName : request.passport.sNoMiddleName,
          passportMiddleName : request.passport.passportMiddleName,
          status : request.status,
          comment : request.comment,
          dateOfBirth : request.passport.dateOfBirth,
          gender : request.passport.gender,
          citizenship : request.passport.citizenship,
          passportNumber : request.passport.passportNumber,
          passportIssuedBy : request.passport.passportIssuedBy,
          passportIssueDate : request.passport.passportIssueDate,
          passportIssuerCode : request.passport.passportIssuerCode,
          placeOfBirth : request.passport.placeOfBirth,
          registrationAddress : request.passport.registrationAddress,
          failKycComments : request.failKycComments,
          step: request.step,
        })      );      
        this.totalElements = res.data.totalElements;        
      })
      .catch((err) => {
        this.kycRequests = [];
        this.totalElements = 0;
        console.log("loaded err", err);
      })
      .finally(() => (this.loading = false));
    },
    getComment(kycRequest) {
      this.showComment = true;
      this.selectedComment = kycRequest.comment;
      this.selectedRequest = kycRequest;
    },
    declineComment() {
      this.showComment = false;
      this.selectedComment = undefined;
    },
  },
  mounted() {
    this.load({page: this.currentPage});
  },
  data() {
    return {
      status: "NEED_EXAMINATION",
      search: null,
      statuses: [
        { key :"NEED_EXAMINATION", label: "Нуждаются в проверке (CHECKED, MISMATCH_TEXT, FRAUD)" },

        { key :"CHECKED", label: "Автоматически одобрено (CHECKED)" },
        { key :"MISMATCH_TEXT", label: "Несоответствие текста (MISMATCH_TEXT)" },
        { key :"FRAUD", label: "Фото не распознано (FRAUD)" },

        { key :"SUBMITTED", label: "Отправлен на проверку (SUBMITTED)" },
        { key :"API_ERROR", label: "К повторной отправке в DBrain (API_ERROR)" },

        { key :"PASSED", label: "Одобрено (PASSED)" },
        { key :"FAILED", label: "Отказано (FAILED)" }
      ],
      loading: true,
      timeout: 2000,
      showModal: false,
      selectedRequest: Object as () => KycRequest,
      snackbar: false,
      snackbarText: '',
      headers: [
        { title: "Id", key: "userId" },
        { title: "", key: "step" },
        { title: "Время запроса", key: "lastModifiedAt" },
        { title: "Имя", key: "passportFirstName" },
        { title: "Фамилия", key: "passportLastName" },
        { title: "Отчество", key: "passportMiddleName" },
        { title: "Статус", key: "status" },
        { title: "Дата рождения", key: "dateOfBirth" },
        { title: "Пол", key: "gender" },
        { title: "Гражданство", key: "citizenship" },
        { title: "Номер паспорта", key: "passportNumber" },
        { title: "Паспорт выдан", key: "passportIssuedBy" },
        { title: "Дата выдачи", key: "passportIssueDate" },
        { title: "Код подразделения", key: "passportIssuerCode" },
        { title: "Место рождения", key: "placeOfBirth" },
        { title: "Адрес регистрации", key: "registrationAddress" },
        { title: "Комментарий", key: "comment" },
        { title: "Фото паспорта", key: "userUid" },
      ],
      itemsPerPage: 10,
      totalElements: 0,
      currentPage: 0,
      itemsPerPageOptions: [
        { value: 10, title: "10" },
        { value: 25, title: "25" },
        { value: 50, title: "50" },
        { value: 100, title: "100" },
      ],
      kycRequests: [],
      showComment: false,
      selectedComment: undefined,
    };
  },
};
